const svg4Everybody = require('svg4everybody');

class App {
  init() {
    this.launchPew();
    this.launchSvg4Everybody();
    this.classToggle();
  }

  launchPew() {
    if (window.pew) { window.pew.enhanceRegistry(document); }
  }

  launchSvg4Everybody() {
    svg4Everybody();
  }

  classToggle() {
    if (window.innerWidth >= 782) {
      const toggles = document.querySelectorAll('.js-toggle a');
      const classToToggle = 'is-active';
      const parentClassToToggle = 'is-active-parent';

      toggles.forEach(toggle => {
        const target = toggle.closest('.js-toggle').nextElementSibling;
        const parent = toggle.closest('.wp-block-columns');
        if (toggle && target && parent) {
          toggle.addEventListener('click', () => {
            target.classList.toggle(classToToggle);
            parent.classList.toggle(parentClassToToggle);
          });
        }
      });
    }
  }

}

let app = new App();
app.init();
